import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Landing from "./views/Landing.vue";
import Register from "./views/Register.vue";
import Partners from "./views/Partners.vue";
import Faculty from "./views/Faculty.vue";
import Applications from "./views/Applications";

Vue.use(Router);


export default new Router({
  linkExactActiveClass: "active",
  mode: 'history',
  hash: false,
  routes: [
    {
      path: "/",
      name: "landing",
      meta:{
        title:'AI4PH'
      },
      beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://ai4ph-hrtp.ca/ai4ph-summer-institute/")
      },
      components: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      }
    },
    // {
    //   path: "/register",
    //   name: "register",
    //   meta:{
    //     title:'AI4PH - register'
    //   },
    //   components: {
    //     header: AppHeader,
    //     default: Register,
    //     footer: AppFooter
    //   }
    // },
    // {
    //   path: "/partners",
    //   name: "partners",
    //   meta:{
    //     title:'AI4PH - partners'
    //   },
    //   components: {
    //     header: AppHeader,
    //     default: Partners,
    //     footer: AppFooter
    //   }
    // },
    //   {
    //   path: "/faculty",
    //   name: "faculty",
    //   meta:{
    //     title:'AI4PH - Faculty'
    //   },
    //   components: {
    //     header: AppHeader,
    //     default: Faculty,
    //     footer: AppFooter
    //   }
    // },
    // {
    //   path: "/applications",
    //   name: "applications",
    //   meta:{
    //     title:'AI4PH - Call for applications'
    //   },
    //   components: {
    //     header: AppHeader,
    //     default: Applications,
    //     footer: AppFooter
    //   }
    // },
    {
      path: "*",
      component: {
        header: AppHeader,
        default: Landing,
        footer: AppFooter
      },
      beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://ai4ph-hrtp.ca/ai4ph-summer-institute/")
      },
    }
  ],
  // scrollBehavior: to => {
  //   if (to.hash) {
  //     return { selector: to.hash };
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // }
});
