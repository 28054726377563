<template>
    <header class="header-global">
        <headroom>
        <base-nav class="navbar-main" type="" effect="dark" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                <img :src="`img/brand/white${$t('locale')}.png`" alt="logo">
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                    <router-link to="/">
                       <div @click="closeMenu"><img :src="`img/brand/blue${$t('locale')}.png`" alt="logo"></div>
                    </router-link>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
              <li class="nav-item">
                <router-link class="nav-link nav-link-icon"  to="/applications">
                  <span class="nav-link-inner--text">{{ $t('header.txt.applications') }}</span>
                </router-link>
              </li>
                <li class="nav-item">
                    <router-link class="nav-link nav-link-icon"  to="/partners">
                        <div class="nav-link-inner--text">{{ $t('header.txt.partners') }}</div>
                    </router-link>
                </li>
                   <li class="nav-item">
                    <router-link class="nav-link nav-link-icon"  to="/faculty">
                        <div class="nav-link-inner--text">{{ $t('header.txt.faculty') }}</div>
                    </router-link>
                </li>   
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item">
                <base-button tag="a"
                             class="mb-3 mb-sm-0"
                             type="warning"
                             href="https://events.eply.com/AI4PH2022"
                             target="_blank">
                  <i class="fa fa-paper-plane"></i>
                  <span>{{ $t( "btn.txt.account") }}</span>
                </base-button>
              </li>
                <li class="nav-item">
                    <a class="nav-link nav-link-icon" id="langBtn" @click="toggleLocale" >
                        <span class="nav-link-inner--text">{{$t('btn.txt.lang')}}</span>
                    </a>
                </li>
            </ul>
        </base-nav>
        </headroom>
    </header>
</template>
<script>
    import BaseNav from "@/components/BaseNav";
    import BaseDropdown from "@/components/BaseDropdown";
    import CloseButton from "@/components/CloseButton";
    import { headroom } from "vue-headroom"

    export default {
        components: {
            BaseNav,
            CloseButton,
            BaseDropdown,
            headroom
        },
      methods: {
        toggleLocale(){
          const host = window.location.host,
              hash = window.location.hash;

          const parts = host.replace('www.','').split('.');
          if(parts[0] === 'ai4ph'){
            parts[0] = 'iapsp';
          }
         else if(parts[0]==='iapsp'){
            parts[0] = 'ai4ph';
          }
         else{
            this.$i18n.locale = this.$i18n.locale === 'en' ? 'fr' : 'en';
          }

          window.location.replace('http://'+parts.join('.')+'/'+hash);
        }
      }
    };
</script>
<style>
#langBtn{
  cursor: pointer
}
</style>
