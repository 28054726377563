<template>
    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0">
            <div class="shape shape-style-1 shape-bright shape-skew alpha-4">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--400" no-body>
                    <div class="px-4">
                        <div class="text-center pt-50  partnerTitle">
                            <h1>{{ $t("partner.txt.funder") }}</h1>
                        </div>
                         <div class="row justify-content-center pt-50 pb-50 " style="text-align: center;">
                            <div class="row col-lg-12">
                                <a class="col-lg-12 " target="_blank" href="https://cihr-irsc.gc.ca/e/193.html">
                                    <img  v-lazy="'img/partnerLogo/CIHR.png'" alt="Canadian Institutes for Health Research" />
                                    <p><span>{{ $t('funder.CIHR') }}</span></p>
                                </a>
                            </div>
                         </div>
                        <div class="text-center pt-50  partnerTitle">
                            <h1>{{ $t("partner.txt.title") }}</h1>
<!--                            <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>Solution Manager - Creative Tim Officer</div>-->
<!--                            <div><i class="ni education_hat mr-2"></i>University of Computer Science</div>-->
                        </div>
                        <div class="row justify-content-center pt-50 pb-50 partnerList">
                            <div class="row col-lg-12">
                                <a class="col-lg-4 " target="_blank" href="https://ivado.ca/en/">
                                    <img  v-lazy="'img/partnerLogo/IVADO.png'" alt="Institute for Data Valorisation" />
                                    <span>{{ $t('partner.IVADO') }}</span>
                                </a>
                                <a class="col-lg-4 " target="_blank" href="https://vectorinstitute.ai/">
                                    <img  v-lazy="'img/partnerLogo/VectorInstitute.png'" alt="Vector Institute" />
                                    <span>{{ $t('partner.vector')}}</span>
                                </a>
                                <a class="col-lg-4 " target="_blank" href="https://www.santepop.qc.ca/en" style="height:230px">
                                    <img  v-lazy="'img/partnerLogo/SantedesPop.png'" alt="Quebec Population Health Research Network"/>
                                    <span>{{ $t('partner.santepop') }}</span>
                                </a>
                            </div>
                            <div class="row col-lg-12">
                                <a class="col-lg-4 " target="_blank" href="https://www.publichealthontario.ca/">
                                    <img  v-lazy="'img/partnerLogo/pho.png'" alt="Public Health Ontario" />
                                    <span>{{$t('partner.pho')}}</span>
                                </a>
                                <a class="col-lg-4 " target="_blank" href="https://mila.quebec/en/">
                                    <img  v-lazy="'img/partnerLogo/mila.png'" alt="Mila" />
                                    <span>{{$t('partner.mila')}}</span>
                                </a>
                                <a class="col-lg-4 " target="_blank" href="https://www.cifar.ca/">
                                    <img  v-lazy="'img/partnerLogo/CIFAR.png'" alt="CIFAR" />
                                    <span>{{$t('partner.cifar')}}</span>
                                </a>
                            </div>
                            <div class="row col-lg-12">
                                <a class="col-lg-4 " target="_blank" href="https://chimb.ca/" style="height:230px">
                                    <img  v-lazy="'img/partnerLogo/chi.png'" alt="George & Fay Yee Centre for Healthcare Innovation" />
                                    <span>{{$t('partner.chimb')}}</span>
                                </a>
                                <a class="col-lg-4 " target="_blank" href="https://www.amii.ca/">
                                    <img  v-lazy="'img/partnerLogo/amii.png'" alt="Amii" />
                                    <span>{{$t('partner.amii')}}</span>
                                </a>
                                <a class="col-lg-4 " target="_blank" href="https://www.statcan.gc.ca/eng/">
                                    <img  v-lazy="'img/partnerLogo/statisticsCanada.svg'" alt="Statistics Canada" />
                                    <span>{{$t('partner.statcan')}}</span>
                                </a>
                            </div>
                            <div class="row col-lg-12">
                                <a class="col-lg-4 " target="_blank" href="https://www.inspq.qc.ca/en">
                                    <img  v-lazy="'img/partnerLogo/INSPQ.png'" alt="INSPQ" />
                                    <span>{{$t('partner.INSPQ')}}</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </card>
            </div>
        </section>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                name: "",
                email:"",
                msg:"",
                output:""
            };
        },
        methods: {
            formSubmit(e) {
                e.preventDefault();
                let currentObj = this,
                    bodyFormData = new FormData();
                bodyFormData.set('name', this.name);
                bodyFormData.set('email', this.email);
                bodyFormData.set('msg', this.msg);
                this.$axios(
                    {method: 'post',
                        url: 'http://132.216.183.12/message.php',
                        data: bodyFormData,
                        headers: {'Content-Type': 'multipart/form-data' }
                    }
                )
                    .then(function (response) {
                        currentObj.output = response.data;
                        currentObj.name="";
                        currentObj.msg="";
                        currentObj.email="";
                    })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
            }
    }
    };
</script>
<style>
</style>
