<template>
  <div class="position-relative">
    <section class="section section-shaped section-lg my-0">
      <div class="shape shape-style-1 shape-primary">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-12 order-md-1">
            <div class="pr-md-5">
              <!--                                <icon name="ni ni-settings-gear-65" class="mb-5" size="lg" type="success" shadow-->
              <!--                                      rounded></icon>-->
              <h2 class="text-Light font-weight-bold">{{ $t('app.txt.title')}}</h2>
              <h5  class="text-Light font-weight-bold">{{ $t('app.txt.subtitle') }}</h5>
              <br>
              <p class="lead text-Light ">{{ $t('app.txt.description')}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section section-lg">
      <div class="container">
        <div class="row row-grid align-items-center">
          <div class="col-md-12">
            <div class="pl-md-5 pb-30">
              <!--                                    <icon name="ni ni-settings" class="mb-5" size="lg" type="warning" shadow rounded></icon>-->

              <h3>{{$t('app.txt.who')}}</h3>
              <p class="lead">{{$t('app.txt.whoTxt1') }}</p>
              <p class="lead">{{$t('app.txt.whoTxt2') }}</p>
            </div>

            <div class="pl-md-5 pb-30">
              <h3>
                {{$t('app.txt.how') }}
              </h3>
              <p class="lead">
                {{$t('app.txt.howTxt1') }}
              </p>
              <p class="lead">
                {{$t('app.txt.howTxt2') }}
              </p>
              <p class="lead">
                {{$t('app.txt.howTxt3') }}
              </p>
              <a href="https://events.eply.com/AI4PH2022" target="_blank">https://events.eply.com/AI4PH2022</a>
              <p class="lead">
                {{$t('app.txt.howTxt4') }}
              </p>
            </div>

            <div class="pl-md-5 pb-30">
              <h3>
                {{$t('app.txt.new') }}
              </h3>
              <ul>
                <li class="lead">
                  {{$t('app.txt.new1') }}
                </li>
                <li class="lead">
                  {{$t('app.txt.new2') }}
                </li>
                 <li class="lead" style="list-style-type: none;">
                    <strong><a HREF="/pdf/program.pdf" target="_blank">{{$t('app.txt.benefitList5') }} </a>  </strong>
                 </li>
                <li class="lead">
                  {{$t('app.txt.new3') }}
                </li>
                <li class="lead">
                  {{$t('app.txt.new4') }}
                </li>
                <li class="lead">
                  {{$t('app.txt.new5') }}
                  <br><a style="font-size: 1rem;color:#024f6d;font-weight: 400"  target="_blank" href="https://www.canada.ca/en/institutes-health-research/news/2022/03/health-research-training-platforms.html">https://www.canada.ca/en/institutes-health-research/news/2022/03/health-research-training-platforms.html</a>
                </li>

             </ul>
            </div>

            <div class="pl-md-5 pb-30">
              <h3>
                {{$t('app.txt.benefit') }}
              </h3>
              <p class="lead">
                {{$t('app.txt.benefitTxt') }}
              </p>
              <ul>
                <li class="lead">
                  {{$t('app.txt.benefitList1') }}
                </li>
                <li class="lead">
                  {{$t('app.txt.benefitList2') }}
                </li>
                <li class="lead">
                  {{$t('app.txt.benefitList3') }}
                </li>
                <li class="lead">
                  {{$t('app.txt.benefitList4') }}
                </li>
              </ul>
            </div>
            <div class="pl-md-5">
              <h3>
                {{$t('app.txt.partner') }}
              </h3>
              <p class="lead">
                {{$t('app.txt.partnerTxt1') }}
              </p>
              <p class="lead">
                {{$t('app.txt.partnerTxt2') }}
              </p>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>



<script>
export default {
  name: "Applications"
}
</script>

<style scoped>

</style>
