<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-primary shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-10">
                                <h1 class="display-3  text-white">{{ $t('landing.txt.title')}}
                                </h1>
                              <h1 class="display-3  text-white">{{ $t('landing.txt.subtitle')}}
                              </h1>
                              <p class="lead  text-white"> {{$t('landing.txt.description')}}</p>

<!--                              <p class="lead  text-white" style="font-size: 140%; fontWeight: bolder;">{{$t('landing.txt.announce1')}}</p>-->
                              <div class="row flex mt-4 mb-4 pb-3">
                                <div class="">
                                  <div style="text-align: center">
                                    <span class="display-5 text-white" style="font-size: 140%; fontWeight: bolder;">{{$t('landing.txt.date1')}}</span>
                                  </div>
                                </div>

                                <div class="mt-2 mb-2">
                                  <div class="divider"></div>
                                </div>
                                <div class="col-7">
                                    <span class="display-5 text-white" style="font-size: 140%; fontWeight: bolder;">{{$t('landing.txt.announce1')}}</span></div>
                              </div>
                               <!--
                              <div class="container">
                                <card gradient="warning mt-4"
                                      no-body
                                      shadow-size="lg"
                                      class="border-0">
                                  <div class="p-5">
                                    <div class="row align-items-center">
                                      <div class="col-lg-6">
                                        <h3 class="text-white">{{$t('landing.txt.applicationOpen')}}</h3>
                                        <p class="lead text-white mt-3">{{$t('landing.txt.applicationClosed2')}}</p>
                                      </div>
                                      <div class="col-lg-6 ml-lg-auto" style="text-align: right">
                                        <div class="btn-wrapper">
                                          <base-button tag="a"
                                                       class="mb-3 mb-sm-0"
                                                       type="white">
                                            <router-link to="/register">
                                              <i class="fa fa-edit mr-2"></i>
                                              {{$t('btn.txt.mail')}}
                                            </router-link>
                                          </base-button>


                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </card>
                              </div>  -->

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-lg-0 mt--200">
    <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-6">
                        <div class="card bg-default shadow border-0">
                            <img v-lazy="'img/theme/img-1-1200x1000.jpg'" class="card-img-top">
                            <blockquote class="card-blockquote">
                                <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 583 95"
                                        class="svg-bg">
                                    <polygon points="0,52 583,95 0,95" class="fill-default"></polygon>
                                    <polygon points="0,42 583,95 683,0 0,95" opacity=".2" class="fill-default"></polygon>
                                </svg>
                                <h4 class="display-3 font-weight-bold text-white">{{ $t('landing.txt.aim') }}</h4>
                                <p class="lead text-italic text-white">{{ $t('landing.txt.aimTxt') }}</p>
                            </blockquote>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="pl-md-5">
                            <h3>{{ $t('landing.txt.audience') }}</h3>
                            <p class="lead"> {{ $t('landing.txt.audienceTxt1') }}</p>
                            <p>{{ $t('landing.txt.audienceTxt2') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>                                

    </div>
</template>

<script>
export default {
  name: "home",
  components: {}
};
</script>
