<template>
    <footer class="footer">

        <div class="container">
            <div class="row row-grid align-items-center">
                <div class="col-lg-6">                    
                </div>
                <div class="col-lg-6 text-lg-center btn-wrapper">
<!--                    <a target="_blank" rel="noopener" href="https://twitter.com/creativetim"-->
<!--                       class="btn btn-neutral btn-icon-only btn-twitter btn-round btn-lg" data-toggle="tooltip"-->
<!--                       data-original-title="Follow us">-->
<!--                        <i class="fa fa-twitter"></i>-->
<!--                    </a>-->
<!--                    <a target="_blank" rel="noopener" href="https://www.facebook.com/creativetim"-->
<!--                       class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"-->
<!--                       data-original-title="Like us">-->
<!--                        <i class="fa fa-facebook-square"></i>-->
<!--                    </a>-->
<!--                    <a target="_blank" rel="noopener" href="https://dribbble.com/creativetim"-->
<!--                       class="btn btn-neutral btn-icon-only btn-dribbble btn-lg btn-round" data-toggle="tooltip"-->
<!--                       data-original-title="Follow us">-->
<!--                        <i class="fa fa-dribbble"></i>-->
<!--                    </a>-->
<!--                    <a target="_blank" rel="noopener" href="https://github.com/creativetimofficial"-->
<!--                       class="btn btn-neutral btn-icon-only btn-github btn-round btn-lg" data-toggle="tooltip"-->
<!--                       data-original-title="Star on Github">-->
<!--                        <i class="fa fa-github"></i>-->
<!--                    </a>-->
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <img :src="`img/brand/white${$t('locale')}.png`" style="height:30px;">
                </div>

                <div class="col-md-3">
                    <div class="attributions">
                        <a @click="modal.show = true">Attributions</a>

                        <modal :show.sync="modal.show" class="attribution-modal">
                            <h6 slot="header" class="modal-title" id="modal-title-default">Attributions</h6>

                            <a href="https://www.freepik.com/free-photos-vectors/infographic">Infographic vector created by freepik - www.freepik.com</a>

                            <template slot="footer">
                                <!--                                <base-button type="primary">Save changes</base-button>-->
                                <base-button type="link" class="ml-auto" @click="modal.show = false">Close
                                </base-button>
                            </template>
                        </modal>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="copyright">
                        &copy; Copyright {{year}}
                    </div>
                </div>

            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear(),
        modal: {
            show: false
        }
    }
  }
};
</script>
<style>
</style>
