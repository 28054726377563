<template>
    <section class="section section-shaped section-lg my-0">
        <div class="shape shape-style-1 shape-bright">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container pt-lg-md">
            <div class="row justify-content-center">
                <div class="col-lg-5">
                    <card type="bright" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-5 py-lg-5"
                          class="border-0">
                        <template>
                            <div class="text-center text-muted mb-4 card-title">
                                <span>Join our mailing list for updates</span>
                            </div>
                            <form role="form" @submit="formSubmit">
                                    <base-input required
                                                class="mb-3"
                                                label="First Name"
                                                placeholder="First Name"
                                                v-model="firstname">
                                    </base-input>
                                    <base-input required
                                                class="mb-3"
                                                label="Last Name"
                                                placeholder="Last Name"
                                                v-model="lastname">
                                    </base-input>

                                <base-input required
                                            class="mb-3"
                                            placeholder="Email"
                                            label="Email"
                                            type="email"
                                            v-model="email">
<!--                                            addon-left-icon="ni ni-email-83">-->
                                </base-input>

                                <div class="text-center">
                                    <base-button type="submit" class="my-4">Register to mailing list</base-button>
                                </div>
                            </form>
                            <pre>
                        {{output}}
                        </pre>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            firstname: "",
            lastname:"",
            email:"",
            output:""
        };
    },
    methods: {
        formSubmit(e) {
            e.preventDefault();
            let currentObj = this,
                bodyFormData = new FormData();
            bodyFormData.set('firstname', this.firstname);
            bodyFormData.set('lastname', this.lastname);
            bodyFormData.set('email', this.email);
            this.$axios(
                {method: 'post',
                    url:   "/register.php" ,
                    data: bodyFormData,
                    headers: {'Content-Type': 'multipart/form-data',"Access-Control-Allow-Origin": "*", }
                }
            )
                .then(function (response) {
                    currentObj.output = response.data;
                    currentObj.firstname="";
                    currentObj.lastname="";
                    currentObj.email="";
                })
                .catch(function (error) {
                    currentObj.output = error;
                });
        }
    }
};
</script>
<style>
</style>
