
import Vue from "vue";
import App from "./App.vue";
import index from "./router";
import Argon from "./plugins/argon-kit";
import axios from 'axios';
import VueAxios from 'vue-axios'
import './registerServiceWorker';
import 'bootstrap-vue'
import { BootstrapVue} from 'bootstrap-vue'
import i18n from '@/plugins/i18n';


Vue.config.productionTip = false;

const host = window.location.host;
const parts = host.replace('www.','').split('.');
const domainLength = 2; // ai4ph.com => domain length = 2

const router = () => {
  if (parts[0] === 'ai4ph') {
    i18n.locale = "en"
  } else if (parts[0] === 'iapsp') {
    i18n.locale = "fr";
  }
  return index;
};

Vue.config.productionTip = false;
Vue.use(Argon, VueAxios, axios);
Vue.use(BootstrapVue);
Vue.prototype.$axios = axios;

new Vue({
  router:router(),
  i18n,
  render: h => h(App)
}).$mount("#app");
